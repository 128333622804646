// cache.js
//Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/utils/cache.js

// Cache keys and initializations
export const CACHE_KEY = 'propertyDetailCache';
export const SIMILAR_PROPERTIES_CACHE_KEY = 'similarPropertiesCache';
export const IMAGE_CACHE_KEY = 'imageCache';

export let propertyDetailCache = JSON.parse(localStorage.getItem(CACHE_KEY)) || {};
export let similarPropertiesCache = JSON.parse(localStorage.getItem(SIMILAR_PROPERTIES_CACHE_KEY)) || {};
export let imageCache = JSON.parse(localStorage.getItem(IMAGE_CACHE_KEY)) || {};
export const POLYGON_SEARCH_CACHE_KEY = 'polygonSearchCache'; // Define a key for the polygon search cache
export let polygonSearchCache = JSON.parse(localStorage.getItem(POLYGON_SEARCH_CACHE_KEY)) || {}; // Initialize the polygon cache

// Cache settings
export const cacheDuration = 7 * 24 * 60 * 60 * 1000; // 1 week
export const cacheTimeout = 3000000; // 5 minutes 

// Helper functions
export const isCacheExpired = (expiry) => {
  const currentTime = Date.now();
  /* console.log(`Checking if cache expired. Expiry time: ${expiry}, Current time: ${currentTime}, Expired: ${currentTime > expiry}`); */
  return currentTime > expiry;
};

export const clearCache = () => {
  propertyDetailCache = {};
  similarPropertiesCache = {};
  imageCache = {};
  localStorage.removeItem(CACHE_KEY);
  localStorage.removeItem(SIMILAR_PROPERTIES_CACHE_KEY);
  localStorage.removeItem(IMAGE_CACHE_KEY);
};

const clearOldestCacheEntries = (cache, percentage) => {
  const keys = Object.keys(cache);
  const numberOfEntriesToRemove = Math.ceil(keys.length * percentage);
  /* console.log(`Clearing ${numberOfEntriesToRemove} oldest cache entries. Total entries before: ${keys.length}`); */

  keys.sort((a, b) => cache[a].timestamp - cache[b].timestamp);
  for (let i = 0; i < numberOfEntriesToRemove; i++) {
    /* console.log(`Removing cache entry for key: ${keys[i]}`); */
    delete cache[keys[i]];
  }

  /* console.log(`Total entries after cleanup: ${Object.keys(cache).length}`); */
};

const updateCache = () => {
  try {
/*     console.log('Updating cache in localStorage:', {
      propertyDetailCache,
      similarPropertiesCache,
      imageCache,
      polygonSearchCache, // Include polygon search cache here
    }); */
    localStorage.setItem(CACHE_KEY, JSON.stringify(propertyDetailCache));
    localStorage.setItem(SIMILAR_PROPERTIES_CACHE_KEY, JSON.stringify(similarPropertiesCache));
    localStorage.setItem(IMAGE_CACHE_KEY, JSON.stringify(imageCache));
    localStorage.setItem(POLYGON_SEARCH_CACHE_KEY, JSON.stringify(polygonSearchCache)); // Store polygon search cache
/*     console.log('Cache updated successfully in localStorage.'); */
  } catch (error) {
    console.error('Failed to update cache in localStorage:', error);
  }
};

// Property Detail Cache
export const normalizeKey = (key) => {
  return String(key).toLowerCase().trim();  // Normalize key consistently
};

export const normalizeAddressKey = (address) => {
  return `${address.streetAddress} ${address.city} ${address.state} ${address.zipcode} usa`.toLowerCase().trim();
};

export const setCachedPropertyDetail = (key, data) => {
  key = normalizeKey(key); // Normalize key

  /* console.log(`Setting cache for key: ${key} with data:`, data); */

  // Cache the data using both address and zpid
  propertyDetailCache[key] = {
    data,
    timestamp: Date.now(),
  };

  // If the data contains a zpid, cache it under the zpid key as well
  if (data.zpid) {
    const zpidKey = normalizeKey(data.zpid);
    propertyDetailCache[zpidKey] = {
      data,
      timestamp: Date.now(),
    };
    /* console.log(`Cache updated for ZPID key: ${zpidKey}`); */
  }

  updateCache(); // Sync with localStorage or other storage mechanisms
};

export const getCachedPropertyDetail = (key) => {
  key = normalizeKey(key);  // Normalize key

  /* console.log(`Attempting cache read for key: ${key}`); */
  const cachedDetail = propertyDetailCache[key];

  if (cachedDetail) {
    const isExpired = isCacheExpired(cachedDetail.timestamp + cacheTimeout);
    /* console.log(`Cache read for key: ${key}, Data:`, cachedDetail, `Expired: ${isExpired}`); */

    if (!isExpired) {
      /* console.log(`Cache hit for key: ${key}`); */
      return cachedDetail.data;
    }
  }

  /* console.log(`Cache miss or expired for key: ${key}`); */
  return null;
};

// Polygon Cache
export const setCachedPolygonSearch = (key, data) => {
  if (!key) {
    console.error("Attempted to set cache with an undefined key:", key);
    return;
  }

  // Proceed if the key is valid
  polygonSearchCache[key] = {
    data,
    timestamp: Date.now(),
  };

  updateCache(); // Sync with localStorage or other storage mechanisms
};

// Retrieve from polygon cache
export const getCachedPolygonSearch = (key) => {
  key = normalizeKey(key); // Normalize key
  /* console.log(`Attempting cache read for polygon key: ${key}`); */
  const cachedPolygon = polygonSearchCache[key]; // Read from the correct cache

  if (cachedPolygon) {
    const isExpired = isCacheExpired(cachedPolygon.timestamp + cacheTimeout);
    /* console.log(`Cache read for key: ${key}, Data:`, cachedPolygon, `Expired: ${isExpired}`); */

    if (!isExpired) {
      /* console.log(`Polygon cache hit for key: ${key}, with data:`, cachedPolygon.data); */
      return cachedPolygon.data;
    }
  }

  /* console.log(`Cache miss or expired for polygon key: ${key}`); */
  return null;
};


// Similar Properties Cache
export const setCachedSimilarProperties = (key, data) => {
  key = normalizeKey(key); // Normalize the key

  /* console.log(`Setting cache for similar properties key: ${key} with data:`, data); */

  similarPropertiesCache[key] = {
    data,
    timestamp: Date.now(), // Store the current timestamp
  };

  updateCache(); // Sync with localStorage or other storage mechanisms
};

export const getCachedSimilarProperties = (key) => {
  key = normalizeKey(key);  // Normalize key

  /* console.log(`Attempting cache read for similar properties key: ${key}`); */
  const cachedSimilarProperties = similarPropertiesCache[key];

  if (cachedSimilarProperties) {
    const isExpired = isCacheExpired(cachedSimilarProperties.timestamp + cacheTimeout);
    /* console.log(`Cache read for similar properties key: ${key}, Data:`, cachedSimilarProperties, `Expired: ${isExpired}`); */

    if (!isExpired) {
      /* console.log(`Similar properties cache hit for key: ${key}`); */
      return cachedSimilarProperties.data;
    }
  }

  /* console.log(`Cache miss or expired for similar properties key: ${key}`); */
  return null;
};

// Image Cache
export const setCachedImages = (key, data) => {
  key = normalizeKey(key); // Normalize the key to ensure consistency

  /* console.log(`Setting cache for image key: ${key} with data:`, data); */

  imageCache[key] = {
    data,
    timestamp: Date.now(), // Store the current timestamp
  };

  updateCache(); // Sync with localStorage or other storage mechanisms
};

export const getCachedImages = (key) => {
  key = normalizeKey(key); // Normalize the key

  /* console.log(`Attempting cache read for image key: ${key}`); */
  const cachedImages = imageCache[key];

  if (cachedImages) {
    const isExpired = isCacheExpired(cachedImages.timestamp + cacheTimeout);
    /* console.log(`Cache read for image key: ${key}, Data:`, cachedImages, `Expired: ${isExpired}`); */

    if (!isExpired) {
      /* console.log(`Image cache hit for key: ${key}`); */
      return cachedImages.data;
    }
  }

  /* console.log(`Cache miss or expired for image key: ${key}`); */
  return null;
};
