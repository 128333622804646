// ErrorBoundary.jsx
// Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/utils/ErrorBoundry.jsx
import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error("ErrorBoundary caught an error", error, errorInfo);

    // Check if the error is related to map initialization and ignore it
    if (error.message.includes('Map container is already initialized')) {
      console.warn('Map initialization error caught and ignored');
      this.setState({ hasError: false, error: null });
      return;
    }

    // For other errors, update the state
    this.setState({ hasError: true, error });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex flex-col items-center justify-center h-screen bg-purple-800/60 text-white text-center p-12 m-1 rounded-lg animate-fadeIn shadow-lg">
          <div className="bg-purple-900/70 p-10 rounded-lg shadow-lg max-w-2xl">
            <div className='flex flex-col items-center gap-2 justify-center'>
              <img src="/assets/Icecream.gif" alt="Icecream" className="mb-6 w-48 h-48 object-contain" />
              <span className="text-5xl mb-6 font-extrabold align-bottom">Oops!</span>
            </div>
            <h1 className="text-5xl mb-6 font-extrabold"> Something went wrong, but we're working on it!</h1>
            <p className="text-lg mb-8 leading-7"> Please reload the page and try again. If the issue persists, please contact <a href="mailto:support@spearrealestategroup.homes" className="text-blue-400 underline">support@spearrealestategroup.homes</a> for assistance.</p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
