// mapUtils.js
// Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/utils/mapUtils.js

import L from 'leaflet';

export const DEFAULT_CENTER = { lat: 45.523064, lng: -122.676483 };
export const DEFAULT_ZOOM = 11; // Default zoom level

export const calculateBounds = (listings) => {
  const bounds = {
    minLat: Infinity,
    minLng: Infinity,
    maxLat: -Infinity,
    maxLng: -Infinity,
  };

  listings.forEach((listing) => {
    if (listing.latitude && listing.longitude && !isNaN(listing.latitude) && !isNaN(listing.longitude)) {
      if (listing.latitude < bounds.minLat) bounds.minLat = listing.latitude;
      if (listing.latitude > bounds.maxLat) bounds.maxLat = listing.latitude;
      if (listing.longitude < bounds.minLng) bounds.minLng = listing.longitude;
      if (listing.longitude > bounds.maxLng) bounds.maxLng = listing.longitude;
    }
  });

  if (bounds.minLat === Infinity || bounds.minLng === Infinity ||
    bounds.maxLat === -Infinity || bounds.maxLng === -Infinity) {
    return {
      minLat: DEFAULT_CENTER.lat - 0.05,
      minLng: DEFAULT_CENTER.lng - 0.05,
      maxLat: DEFAULT_CENTER.lat + 0.05,
      maxLng: DEFAULT_CENTER.lng + 0.05,
    };
  }

  return bounds;
};


export const getCenterOfBounds = (bounds) => {
  /* console.log('Getting Center of Bounds:', bounds); */
  const lat = (bounds.minLat + bounds.maxLat) / 2;
  const lng = (bounds.minLng + bounds.maxLng) / 2;
  /* console.log("Calculated center", { lat, lng }); */
  return { lat, lng };
};

export const calculateZoomLevel = (map, bounds) => {
  /* console.log('Calculate Zoom Level based on Bounds:', map, bounds); */
  if (!bounds || bounds.minLat === undefined || bounds.minLng === undefined || bounds.maxLat === undefined || bounds.maxLng === undefined) {
    /* console.error("Invalid bounds provided. Returning default zoom level."); */
    return DEFAULT_ZOOM;
  }

  const southWest = L.latLng(bounds.minLat, bounds.minLng);
  const northEast = L.latLng(bounds.maxLat, bounds.maxLng);
  const mapBounds = L.latLngBounds(southWest, northEast);

  const zoom = map.getBoundsZoom(mapBounds, { paddingTopLeft: [10, 10], paddingBottomRight: [10, 10] });
  /* console.log("Calculated zoom level:", zoom); */
  return zoom;
};
