// utils/addressUtils.js
// Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/utils/addressUtils.js

const addressMappings = {
    "st": "street",
    "rd": "road",
    "ave": "avenue",
    "blvd": "boulevard",
    "ln": "lane",
    "dr": "drive",
    "ct": "court",
    "cir": "circle",
    "pl": "place",
    "ter": "terrace",
    "pkwy": "parkway",
    "sq": "square",
    "trl": "trail",
    "hwy": "highway",
    "way": "way",
    "or": "oregon"
};

export const normalizeAddress = (address) => {
    if (!address) return '';

    return address
        .toLowerCase()
        .replace(/,/g, '') // Remove commas
        .replace(/\./g, '') // Remove periods
        .replace(/#/g, '') // Remove hash symbol
        .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
        .split(' ')
        .map((word, index) => {
            // Ensure that numeric street names (e.g., "3rd") aren't confused with street abbreviations
            if (index === 1 && /^\d+(st|nd|rd|th)$/i.test(word)) {
                return word; // Don't map these to street
            }
            return addressMappings[word] || word; // Expand abbreviations
        })
        .join(' ')
        .trim();
};


export const isAddress = (query) => {
    // This pattern ensures the query starts with digits (street number) and includes a street name
    const addressPattern = /^\d+\s+\d*[A-Za-z]+/; 
    return addressPattern.test(query);
};
