//index.js
// Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/index.js

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import './index.css';
import App from './App.mjs';
import LoadingSpinner from './components/loading/LoadingSpinner.jsx';
import { SavedHomesProvider } from './contexts/SavedHomesContext.js';
import AuthProvider from './contexts/AuthProvider.js';
import "leaflet/dist/leaflet.css";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { MapProvider } from './contexts/MapContext';
import 'intersection-observer';
import useProgress from './components/hooks/useProgress';
import { Helmet } from 'react-helmet';

function Root() {
  const { progress, loading, startProgress, completeProgress, updateProgress } = useProgress(1500);
  const navigate = useNavigate();

  useEffect(() => {
    startProgress();
    const timeoutId = setTimeout(() => {
      completeProgress();
    }, 1500);
    return () => clearTimeout(timeoutId);
  }, [startProgress, completeProgress]);

  useEffect(() => {
    if (loading) {
      document.body.classList.add('loading');
    } else {
      document.body.classList.remove('loading');
    }
  }, [loading]);

  useEffect(() => {
    const handleBackButton = (event) => {
      navigate(-1);
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [navigate]);

/*   useEffect(() => {
    window.dataLayer = window.dataLayer || [];
  
    function gtag() {
      dataLayer.push(arguments);
    }
  
    // Check if gtag is loaded
    if (typeof window.gtag === 'function') {
      gtag('js', new Date());
      gtag('config', 'G-EHH43C18D1', {
        page_path: window.location.pathname,
      });
    } else {
      // Load Google Tag Manager asynchronously if not already loaded
      const script = document.createElement('script');
      script.src = 'https://www.googletagmanager.com/gtag/js?id=G-EHH43C18D1';
      script.async = true;
      document.head.appendChild(script);
  
      script.onload = () => {
        gtag('js', new Date());
        gtag('config', 'G-EHH43C18D1', {
          page_path: window.location.pathname,
        });
      };
    }
  }, []); */
  
  // Function to handle escape key press to cancel loading
  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        completeProgress(); // Call the completeProgress to stop loading
      }
    };

    window.addEventListener('keydown', handleEscapeKey);

    return () => {
      window.removeEventListener('keydown', handleEscapeKey);
    };
  }, [completeProgress]);

  const logoPath = process.env.PUBLIC_URL + '/assets/SREG-Logo-Main.webp';
  const faviconPath = process.env.PUBLIC_URL + '/assets/Favicon.webp';
  const mainHousePath = process.env.PUBLIC_URL + '/assets/mainHouse.webp';

  return (
    <>
      <Helmet>
        <link rel="preload" href={logoPath} as="image" />
        <link rel="preload" href={faviconPath} as="image" />
        <link rel="preload" href={mainHousePath} as="image" />
      </Helmet>
      {loading && <LoadingSpinner progress={progress} />}
      <App
        initialLoadingDone={!loading}
        startProgress={startProgress}
        completeProgress={completeProgress}
        updateProgress={updateProgress}
        loading={loading}
      />
    </>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <MapProvider>
      <SavedHomesProvider>
        <Router>
          <Root />
          <SpeedInsights />
        </Router>
      </SavedHomesProvider>
    </MapProvider>
  </AuthProvider>
);
