// Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/App.mjs
/* global hj */

import React, { Suspense, lazy, useState, useEffect, useCallback, useRef, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";
import Swal from "sweetalert2";
import { SpeedInsights } from '@vercel/speed-insights/react';
import { useMapContext } from "./contexts/MapContext.js";
import { normalizeAddress, isAddress } from "./utils/addressUtils.js";
import { DEFAULT_CENTER, } from "./utils/mapUtils.js";
import { fetchAdditionalDetailsByAddress } from "./FetchFunctions/fetchAdditionalDetailsByAddress.jsx";
import { fetchAdditionalDetails } from "./FetchFunctions/fetchAdditionalDetails.jsx";
import { getUserLocation } from "./utils/getUserLocation.js";

import {
  getCachedPolygonSearch,
  setCachedPolygonSearch,

} from "./utils/cache.js";

import axiosRateLimit from "axios-rate-limit";
import GoogleAuthCallback from './components/Login/GoogleAuthCallback.jsx';
import AuthModal from "./components/Login/AuthModal.jsx";
import { ErrorProvider } from "./ErrorProvider.js";
import Navbar from "./components/navbar/NavBar.jsx";
import { TutorialProvider } from "./contexts/TutorialContext.js";
import { ViewModeProvider } from "./contexts/ViewModeContext.js";
import { app } from './components/firebase/Firebase.js';
import { isSupported, getAnalytics } from 'firebase/analytics';

import SitemapPage from "./components/pages/companyResources/SitemapPage.jsx";
import { updateUrlWithSearchParams } from "./utils/urlUtils.js";
import ErrorBoundary from "./utils/ErrorBoundry.jsx";
import TermsOfService from "./components/pages/companyResources/TermsOfService.jsx";


const Home = lazy(() => import('./routes/Home.jsx'));
const LoadingSpinner = lazy(() => import("./components/loading/LoadingSpinner.jsx"));
const Listings = lazy(() => import('./routes/Listings.jsx'));
const Dashboard = lazy(() => import('./components/Login/Dashboard/Dashboard.jsx'));
const ProfileSettings = lazy(() => import('./components/Login/Dashboard/ProfileSettings.jsx'));
const PrivacyPolicy = lazy(() => import('./components/pages/companyResources/PrivacyPolicy.jsx'));
const Contact = lazy(() => import('./components/pages/companyResources/Contact.jsx'));
const AboutUs = lazy(() => import('./components/pages/companyResources/AboutUs.jsx'));
const SearchMortgageRates = lazy(() => import('./components/mortgageSection/SearchMortgageRates.jsx'));
const ListWithUs = lazy(() => import('./components/pages/sellerResources/ListWithUs.jsx'));
const HomeValue = lazy(() => import('./components/pages/sellerResources/HomeValue.jsx'));
const MortgageCalculator = lazy(() => import('./components/mortgageSection/MortgageCalculator.jsx'));
const RefinanceCalculator = lazy(() => import('./components/mortgageSection/RefinanceCalculator.jsx'));
const InvestmentCalculator = lazy(() => import('./components/pages/investorResources/InvestmentCalculator.jsx'));
const RenterResources = lazy(() => import('./components/pages/renterResources/RenterResources.jsx'));
const BuyerResources = lazy(() => import('./components/pages/buyerResources/BuyerResources.jsx'));
const SellerResources = lazy(() => import('./components/pages/sellerResources/SellerResources.jsx'));
const SellerAgentFinderForm = lazy(() => import('./components/pages/sellerResources/SellerAgentFinderForm.jsx'));
const SellerAgentFinderLanding = lazy(() => import('./components/pages/sellerResources/SellerAgentFinderLanding.jsx'));
const InvestorResources = lazy(() => import('./components/pages/investorResources/InvestorResources.jsx'));
const AgentFinderForm = lazy(() => import('./components/pages/buyerResources/AgentFinderForm.jsx'));
const AgentFinderLanding = lazy(() => import('./components/pages/buyerResources/AgentFinderLanding.jsx'));
const BookingPage = lazy(() => import('./components/pages/companyResources/BookingPage.jsx'));
const Reset = lazy(() => import('./components/Login/Reset.js'));
const BlogList = lazy(() => import('./components/pages/blogs/BlogList.jsx'));
const BlogPostDetail = lazy(() => import('./components/pages/blogs/BlogPostDetail.jsx'));
const FeaturedListings = lazy(() => import('./components/navbar/FeaturedListings.jsx'));
const SearchLenders = lazy(() => import('./components/mortgageSection/SearchLenders.jsx'));
const RentalMarketTrends = lazy(() => import('./components/pages/investorResources/RentalMarketTrends.jsx'));
const SiteFooter = lazy(() => import('./components/footer/SiteFooter.jsx'));

const api = axiosRateLimit(axios.create(), { maxRequests: 1, perMilliseconds: 1000 });

const fetchAdditionalDetailsWithCache = async (zpid) => {
  try {
    const response = await fetchAdditionalDetails(zpid);
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const fetchZpidDetailsSequentially = async (zpids, startProgress, updateProgress, completeProgress) => {
  const totalItems = zpids.length;
  const increment = 100 / totalItems;
  const details = [];

  startProgress();

  for (const zpid of zpids) {
    const detail = await fetchAdditionalDetailsWithDelay(zpid, updateProgress, increment);
    if (detail) {
      details.push({ zpid, ...detail });
    }
  }

  completeProgress();

  return details;
};

const fetchAdditionalDetailsWithDelay = async (zpid, updateProgress, increment) => {
  await new Promise(resolve => setTimeout(resolve, 500)); // 0.5 second delay
  const details = await fetchAdditionalDetailsWithCache(zpid);
  updateProgress(increment);
  return details;
};

function App({ initialLoadingDone, startProgress, completeProgress, updateProgress, loading, progress }) {
  const [isAnalyticsInitialized, setIsAnalyticsInitialized] = useState(false);
  const [listings, setListings] = useState([]);
  const [bounds, setBounds] = useState(null);
  const [center, setCenter] = useState(DEFAULT_CENTER);

  const [urlTriggeredUpdate, setUrlTriggeredUpdate] = useState(true);
  const fetchTriggeredRef = useRef(false);


  const [currentProgress, setCurrentProgress] = useState(0);
  const [cachedPropertyDetails, setCachedPropertyDetails] = useState(null);

  useEffect(() => {
    setCurrentProgress(progress);
  }, [progress]);


  useEffect(() => {
    async function setLocationForAnalyticsAndHotjar() {
      try {
        // Check if geolocation is supported by the browser
        if (navigator.geolocation) {
          const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
  
          // Function to fetch and send the location data
          const fetchAndSendLocation = async () => {
            try {
              const location = await getUserLocation();
              if (location) {
                // Hotjar location tagging
                hj('tagRecording', [
                  `city:${location.city}`,
                  `state:${location.state}`,
                  `country:${location.country}`,
                  `postalCode:${location.postalCode}`,
                  `timeZone:${location.timeZone}`,
                  `latitude:${location.latitude}`,
                  `longitude:${location.longitude}`,
                  `metroCode:${location.metroCode}`
                ]);
  
                // GA4 location detection
                if (typeof window.gtag === 'function') {
                  window.gtag('event', 'location_detected', {
                    'user_city': location.city,
                    'user_state': location.state,
                    'user_country': location.country,
                    'user_postal_code': location.postalCode,
                    'user_time_zone': location.timeZone,
                    'user_latitude': location.latitude,
                    'user_longitude': location.longitude,
                    'user_metro_code': location.metroCode,
                    'dimension1': location.city,
                    'dimension2': location.state,
                  });
                } else {
                  console.warn('gtag is not available');
                }
              } else {
                console.warn('Location data is not available');
              }
            } catch (error) {
              console.error('Location detection failed:', error);
            }
          };
  
          // If the permission is already granted, fetch the location immediately
          if (permissionStatus.state === 'granted') {
            fetchAndSendLocation();
          }
  
          // Handle permission changes: listen for when the user grants or denies permission
          permissionStatus.onchange = () => {
            if (permissionStatus.state === 'granted') {
              fetchAndSendLocation();
            } else if (permissionStatus.state === 'denied') {
              console.warn('Location permission denied by user');
            }
          };
        } else {
          console.warn('Geolocation is not supported by this browser');
        }
      } catch (error) {
        console.error('Location detection failed:', error);
      }
    }
  
    setLocationForAnalyticsAndHotjar();
  }, []);
  
  
  


  const [selectedListing, setSelectedListing] = useState(null);
  const [resetData, setResetData] = useState(false);
  const [filters, setFilters] = useState({
    minPrice: "",
    maxPrice: "",
    bedsMin: "",
    bedsMax: "",
    bathsMin: "",
    bathsMax: "",
    sqftMin: "",
    sqftMax: "",
    buildYearMin: "",
    buildYearMax: "",
    lotSizeMin: "",
    lotSizeMax: "",
    daysOn: "",
    daysOnZillow: "",
    status_type: "",
    home_type: "",
    sort: "",
    isOpenHousesOnly: "",
    isComingSoon: "",
    isPendingUnderContract: "",
    isForSaleForeclosure: "",
    isPreForeclosure: "",
    saleByOwner: "",
    saleByAgent: "",
    isParkView: "",
    isWaterView: "",
    isWaterfront: "",
    isCityView: "",
    isMountainView: "",
    page: "",
  });

  const defaultFilters = useMemo(() => ({
    minPrice: "",
    maxPrice: "",
    bedsMin: "",
    bedsMax: "",
    bathsMin: "",
    bathsMax: "",
    sqftMin: "",
    sqftMax: "",
    buildYearMin: "",
    buildYearMax: "",
    lotSizeMin: "",
    lotSizeMax: "",
    daysOn: "",
    daysOnZillow: "",
    status_type: "",
    home_type: "",
    sort: "newest",
    isOpenHousesOnly: "",
    isComingSoon: "",
    isPendingUnderContract: "",
    isForSaleForeclosure: "",
    isPreForeclosure: "",
    saleByOwner: "",
    saleByAgent: "",
    isParkView: "",
    isWaterView: "",
    isWaterfront: "",
    isCityView: "",
    isMountainView: "",
    page: "1",
  }), []);

  /*   const updateMapCenter = (newCenter) => {
      if (newCenter && typeof newCenter.lat === 'number' && typeof newCenter.lng === 'number') {
        if (!searchedCenter || (searchedCenter.lat !== newCenter.lat || searchedCenter.lng !== newCenter.lng)) {
          setSearchedCenter(newCenter);
        }
      } else {
        console.error('Attempted to update map center with invalid value:', newCenter);
      }
    }; */

  const prevSearchTypeRef = useRef();
  const fetchTriggered = useRef(false);

  const [searchType, setSearchType] = useState("");
  const [totalResultCount, setTotalResultCount] = useState(0);
  const [useQuery, setUseQuery] = useState("");
  const length = listings?.length;
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [activeTab, setActiveTab] = useState(''); // default to 'login'

  const host = "zillow-com1.p.rapidapi.com";
  const key = process.env.REACT_APP_API_KEY_RAPID_API_KEY;

  const [polygonCoords, setPolygonCoords] = useState([]);
  const { polygonCoordinates, updatePolygonCoordinates } = useMapContext();
  const [filteredData, setFilteredData] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState(filters);

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const zpid = queryParams.get('zpid');
  const address = queryParams.get('address');
  const addressTriggered = queryParams.get('addressTriggered');
  const searchQuery = queryParams.get("location") || "";

  useEffect(() => {
    if (!isAnalyticsInitialized) {
      isSupported().then((supported) => {
        if (supported) {
          const analytics = getAnalytics(app);
        } else {
          console.warn('Firebase Analytics not supported');
        }
      });
      setIsAnalyticsInitialized(true);
    }
  }, [isAnalyticsInitialized]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const authParam = params.get('auth');

    if (authParam === 'login' || authParam === 'register') {
      setShowAuthModal(true);
    } else {
      setShowAuthModal(false);
    }
  }, [location.search]);

  const closeAuthModal = useCallback(() => {
    setShowAuthModal(false);
    const params = new URLSearchParams(location.search);
    params.delete('auth');
    const newUrl = `${location.pathname}${params.toString() ? `?${params.toString()}` : ''}`;
    navigate(newUrl, { replace: true });
  }, [navigate, location.search]);

  
    // Production Version - Updated with caching and logic from Testing
    const fetchListings = useCallback(
      async (searchQuery, polygonCoords, searchType, triggeredBy = '', updatedFilters = null) => {
  
        if (!searchQuery && !polygonCoords && !useQuery) {
          return;
        }
  
        startProgress();
  
        // Handle different types of polygonCoords (Array, String, etc.)
    let polygonKey = null;
    if (Array.isArray(polygonCoords) && polygonCoords.length > 0) {
      polygonKey = polygonCoords.map(coord => `${coord.lng} ${coord.lat}`).join(",");
    } else if (typeof polygonCoords === 'string' && polygonCoords.length > 0) {
      polygonKey = polygonCoords;  // Handle the case where it's already a formatted string
    }
  
    // Skip polygon cache logic if polygonKey is invalid
    if (searchType === 'polygon' && polygonKey) {
      const cachedPolygonData = getCachedPolygonSearch(polygonKey);
      if (cachedPolygonData) {
        setListings(cachedPolygonData);
        completeProgress();
        return;  // Exit early to prevent further actions
      }
    }
  
  
        setSearchType(searchType);
        setResetData(true);
        const previousSearchType = prevSearchTypeRef.current;
        prevSearchTypeRef.current = searchType;
        const polygon = polygonCoords && polygonCoords.length > 0;
        const effectiveFilters = { ...defaultFilters, ...filters, ...updatedFilters };
        effectiveFilters.status_type = effectiveFilters.status_type || "ForSale";
  
        // Handle address search
      if (isAddress(searchQuery) && searchType === "location") {
        effectiveFilters.address = encodeURIComponent(searchQuery || useQuery);
        delete effectiveFilters.location;
        searchType = "address";

        const newUrl = updateUrlWithSearchParams(
          { ...effectiveFilters, address: searchQuery },
          searchQuery,
          searchType,
          polygonCoords || undefined,
          undefined,
          defaultFilters
        );

        window.history.pushState({ page: window.location.href }, '', newUrl);
        return; // Exit early, no further processing needed
      } else {
          // Check for searchQuery, useQuery, or valid polygon
          if (searchQuery || useQuery || polygon) {
            if (searchType === "address") {
              effectiveFilters.address = encodeURIComponent(searchQuery || useQuery);
              delete effectiveFilters.location;
            } else if (searchType === 'polygon' && searchQuery) {
              let formattedPolygonCoords;
  
              if (Array.isArray(polygonCoords)) {
                formattedPolygonCoords = polygonCoords.map(coord => `${coord.lng} ${coord.lat}`).join(",");
              } else if (typeof polygonCoords === 'string') {
                formattedPolygonCoords = polygonCoords;
              }
  
              polygonKey = formattedPolygonCoords;
              if (!polygonKey) {
                console.error("Polygon key is undefined after formatting. Please check polygon coordinates:", polygonCoords);
                return;
              }
  
              const cachedPolygonData = getCachedPolygonSearch(polygonKey);
              if (cachedPolygonData) {
                setListings(cachedPolygonData);
                completeProgress();
                return;
              }
  
              effectiveFilters.polygon = formattedPolygonCoords;
            } else {
              effectiveFilters.location = encodeURIComponent(searchQuery || useQuery);
            }
          }
        }
  
        Object.keys(effectiveFilters).forEach(key => {
          if (effectiveFilters[key] === '' || (effectiveFilters.status_type === 'ForRent' && ['saleByAgent', 'saleByOwner', 'isForSaleForeclosure', 'isNewConstruction', 'isComingSoon', 'isAuction', 'isPendingUnderContract', 'otherListings'].includes(key))) {
            delete effectiveFilters[key];
          }
        });
  
        if (previousSearchType !== searchType && !updatedFilters?.page) {
          effectiveFilters.page = 1;
        }
  
        const params = { ...effectiveFilters };
  
        if (searchType === 'polygon') {
          let formattedPolygonCoords;
          if (Array.isArray(polygonCoords)) {
            formattedPolygonCoords = polygonCoords.map(coord => `${coord.lng} ${coord.lat}`).join(",");
          } else if (typeof polygonCoords === 'string') {
            formattedPolygonCoords = polygonCoords;
          }
          params.polygon = formattedPolygonCoords;
          delete params.address;
          delete params.location;
        } else if (searchType === 'location') {
          delete params.polygon;
        }
  
        Object.keys(params).forEach(key => {
          if (params[key] === '') {
            delete params[key];
          }
        });
  
        // API call logic
        try {
          let details;
          if (searchType === 'address') {
            details = await fetchAdditionalDetailsByAddress(normalizeAddress(searchQuery));
          } else if (searchType === 'zpid') {
            details = await fetchAdditionalDetails(searchQuery);
          } else {
            // Define the API URL based on environment
            const apiUrl = process.env.NODE_ENV === 'production'
              ? 'https://www.spearrealestategroup.com'
              : 'http://localhost:3000';
  
            // Use fetch to call your server API
            const response = await fetch(`${apiUrl}/api/extendedSearch?${new URLSearchParams(params).toString()}`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            });
  
            const responseData = await response.json();
  
  
            const totalItems = responseData.props ? responseData.props.length : 1;
            const totalResultCountFromResponse = responseData.totalResultCount;
  
            setTotalResultCount(totalResultCountFromResponse);
  
            if (Array.isArray(responseData.props) && responseData.props.length > 0) {
              const validProps = responseData.props.filter(property =>
                property.latitude !== null && property.longitude !== null
              );
  
              setCachedPolygonSearch(polygonKey, validProps);
  
              setListings(validProps);
  
              for (let i = 0; i < validProps.length; i++) {
                const percentage = ((i + 1) / validProps.length) * 100;
                updateProgress(percentage);
              }
  
              completeProgress();
  
              return validProps;
            } else if (responseData.zpid) {
              details = responseData;
            }
          }
  
          if (details) {
            setListings([details]);
            setSelectedListing(details);
            completeProgress();
  
            const newUrl = updateUrlWithSearchParams(
              params,
              searchQuery,
              searchType,
              polygonCoords || undefined,
              details.zpid,
              defaultFilters
            );
            navigate(newUrl, { replace: true });
  
  
            return [details];
          } else {
            setListings([]);
            Swal.fire({
              icon: 'error',
              title: 'No Results Found',
              text: 'No properties found! Adjust your filters and try again, or search a new location.',
            });
          }
          const newUrl = updateUrlWithSearchParams(
            params,
            searchQuery,
            searchType,
            polygonCoords || undefined,
            defaultFilters
          );
          navigate(newUrl, { replace: true });
        } catch (error) {
          console.error(`Error in API call (triggered by ${triggeredBy}):`, error);
          setListings([]);
          const newUrl = updateUrlWithSearchParams(
            params,
            searchQuery,
            searchType,
            polygonCoords || undefined,
            defaultFilters
          );
          navigate(newUrl, { replace: true });
        } finally {
          completeProgress();
        }
      },
      [filters, setPolygonCoords, updatePolygonCoordinates, startProgress, updateProgress, completeProgress, defaultFilters, setSelectedListing, useQuery]
    );
  
  




/*   // Testing Version - Uncomment to use in Testing
  const fetchListings = useCallback(
    async (searchQuery, polygonCoords, searchType, triggeredBy = '', updatedFilters = null) => {
      console.log("fetchListings called with:", { searchQuery, polygonCoords, searchType, triggeredBy, updatedFilters });

      if (!searchQuery && !polygonCoords && !useQuery) {
        console.log("fetchListings aborted due to empty searchQuery, polygonCoords, and useQuery");
        return;
      }

      startProgress();

      // Handle different types of polygonCoords (Array, String, etc.)
      let polygonKey = null;
      if (Array.isArray(polygonCoords) && polygonCoords.length > 0) {
        polygonKey = polygonCoords.map(coord => `${coord.lng} ${coord.lat}`).join(",");
      } else if (typeof polygonCoords === 'string' && polygonCoords.length > 0) {
        polygonKey = polygonCoords;  // Handle the case where it's already a formatted string
      }

      // Skip polygon cache logic if polygonKey is invalid
      if (searchType === 'polygon' && polygonKey) {
        const cachedPolygonData = getCachedPolygonSearch(polygonKey);
        if (cachedPolygonData) {
          setListings(cachedPolygonData);
          completeProgress();
          return;  // Exit early to prevent further actions
        }
      }



      setSearchType(searchType);
      setResetData(true);
      const previousSearchType = prevSearchTypeRef.current;
      prevSearchTypeRef.current = searchType;
      const polygon = polygonCoords && polygonCoords.length > 0;
      const effectiveFilters = { ...defaultFilters, ...filters, ...updatedFilters };
      effectiveFilters.status_type = effectiveFilters.status_type || "ForSale";

      // Handle address search
      if (isAddress(searchQuery) && searchType === "location") {
        effectiveFilters.address = encodeURIComponent(searchQuery || useQuery);
        delete effectiveFilters.location;
        searchType = "address";

        const newUrl = updateUrlWithSearchParams(
          { ...effectiveFilters, address: searchQuery },
          searchQuery,
          searchType,
          polygonCoords || undefined,
          undefined,
          defaultFilters
        );
        console.log("Updating URL to:", newUrl);
        window.history.pushState({ page: window.location.href }, '', newUrl);
        return; // Exit early, no further processing needed
      } else {
        // Check for searchQuery, useQuery, or valid polygon
        if (searchQuery || useQuery || polygon) {
          if (searchType === "address") {
            effectiveFilters.address = encodeURIComponent(searchQuery || useQuery);
            delete effectiveFilters.location;
          } else if (searchType === 'polygon' && searchQuery) {
            // Handle polygon search
            let formattedPolygonCoords;

            if (Array.isArray(polygonCoords)) {
              formattedPolygonCoords = polygonCoords.map(coord => `${coord.lng} ${coord.lat}`).join(",");
            } else if (typeof polygonCoords === 'string') {
              formattedPolygonCoords = polygonCoords;
            }

            // Assign polygonKey and ensure it is valid
            polygonKey = formattedPolygonCoords;
            if (!polygonKey) {
              console.error("Polygon key is undefined after formatting. Please check polygon coordinates:", polygonCoords);
              return;
            }

            // Check if polygon data is cached
            // If polygon data is cached, use it and exit early
            const cachedPolygonData = getCachedPolygonSearch(polygonKey);
            if (cachedPolygonData) {
              console.log("Cache hit for polygon search:", cachedPolygonData);
              setListings(cachedPolygonData);
              completeProgress();
              return;  // Exit early, so no API call or re-fetch happens
            }

            // If no cache hit, set polygon in effectiveFilters for API request
            effectiveFilters.polygon = formattedPolygonCoords;
          } else {
            effectiveFilters.location = encodeURIComponent(searchQuery || useQuery);
          }
        }
      }

      // Clean up filters to remove empty values
      Object.keys(effectiveFilters).forEach(key => {
        if (effectiveFilters[key] === '' || (effectiveFilters.status_type === 'ForRent' && ['saleByAgent', 'saleByOwner', 'isForSaleForeclosure', 'isNewConstruction', 'isComingSoon', 'isAuction', 'isPendingUnderContract', 'otherListings'].includes(key))) {
          delete effectiveFilters[key];
        }
      });

      // Reset page if search type changes and page isn't explicitly set
      if (previousSearchType !== searchType && !updatedFilters?.page) {
        effectiveFilters.page = 1;
      }

      // Adjust params for the API request
      const params = { ...effectiveFilters };

      if (searchType === 'polygon') {
        let formattedPolygonCoords;
        if (Array.isArray(polygonCoords)) {
          formattedPolygonCoords = polygonCoords.map(coord => `${coord.lng} ${coord.lat}`).join(",");
        } else if (typeof polygonCoords === 'string') {
          formattedPolygonCoords = polygonCoords;
        }
        params.polygon = formattedPolygonCoords;
        delete params.address;
        delete params.location;
      } else if (searchType === 'location') {
        delete params.polygon;
      }

      Object.keys(params).forEach(key => {
        if (params[key] === '') {
          delete params[key];
        }
      });

      // API call logic
      try {
        let details;
        if (searchType === 'address') {
          details = await fetchAdditionalDetailsByAddress(normalizeAddress(searchQuery));
        } else if (searchType === 'zpid') {
          details = await fetchAdditionalDetails(searchQuery);
        } else {
          const response = await axios.request({
            method: "GET",
            url: "https://zillow-com1.p.rapidapi.com/propertyExtendedSearch",
            params: params,
            headers: {
              "x-rapidapi-key": key,
              "x-rapidapi-host": host,
            },
          });
          console.log("App - API Response:", response.data);

          const totalItems = response.data.props ? response.data.props.length : 1;
          const totalResultCountFromResponse = response.data.totalResultCount;

          setTotalResultCount(totalResultCountFromResponse);

          if (Array.isArray(response.data.props) && response.data.props.length > 0) {
            const validProps = response.data.props.filter(property =>
              property.latitude !== null && property.longitude !== null
            );

            // Cache the results by polygon key
            setCachedPolygonSearch(polygonKey, validProps);

            setListings(validProps);

            for (let i = 0; i < validProps.length; i++) {
              const percentage = ((i + 1) / validProps.length) * 100;
              updateProgress(percentage);
            }

            completeProgress();
            console.log("fetchListings - Listings set:", validProps);
            return validProps;
          } else if (response.data.zpid) {
            details = response.data;
          }
        }

        if (details) {
          setListings([details]);
          setSelectedListing(details);
          completeProgress();

          const newUrl = updateUrlWithSearchParams(
            params,
            searchQuery,
            searchType,
            polygonCoords || undefined, // Ensure polygonCoords are passed or set as undefined
            details.zpid,
            defaultFilters
          );
          navigate(newUrl, { replace: true });

          console.log("fetchListings - Single property set:", details);
          return [details];
        } else {
          setListings([]);
          Swal.fire({
            icon: 'error',
            title: 'No Results Found',
            text: 'No properties found! Adjust your filters and try again, or search a new location.',
          });
        }
        const newUrl = updateUrlWithSearchParams(
          params,
          searchQuery,
          searchType,
          polygonCoords || undefined, // Ensure polygonCoords are passed or set as undefined
          defaultFilters
        );
        navigate(newUrl, { replace: true });
      } catch (error) {
        console.error(`Error in API call (triggered by ${triggeredBy}):`, error);
        setListings([]);
        const newUrl = updateUrlWithSearchParams(
          params,
          searchQuery,
          searchType,
          polygonCoords || undefined, // Ensure polygonCoords are passed or set as undefined
          defaultFilters
        );
        navigate(newUrl, { replace: true });
      } finally {
        completeProgress();
      }
    },
    [filters, setPolygonCoords, updatePolygonCoordinates, startProgress, updateProgress, completeProgress, defaultFilters, setSelectedListing, useQuery]
  );
 */

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchQuery = queryParams.get("location") || "";
    const polygon = queryParams.get("polygon");
    const zpid = queryParams.get("zpid"); // Fetch the zpid param
    let address = queryParams.get("address");
  
    // Helper function to determine if the searchQuery is a street name
    const isStreetName = (query) => {
      const streetKeywords = ['Ave', 'St', 'Blvd', 'Rd', 'Dr', 'Ln', 'Way']; // Extend as necessary
      return streetKeywords.some(keyword => query.includes(keyword));
    };
  
    // If location contains a full address or a street name, move it to the address param
    if (!address && searchQuery && (isAddress(searchQuery) || isStreetName(searchQuery))) {
      address = searchQuery;
      queryParams.set("address", address);
      queryParams.delete("location");
      window.history.replaceState(null, '', `${location.pathname}?${queryParams.toString()}`);
      
      startProgress();
      setUrlTriggeredUpdate(true);  // Ensure Listings.jsx handles the modal opening
    }
  
    if (urlTriggeredUpdate) {
      const urlFilters = Object.fromEntries(queryParams.entries());
      completeProgress();
      // Set the filters and search query state
      setUseQuery(zpid || address || searchQuery); // Prioritize zpid, then address, fallback to location
      setFilters({ ...defaultFilters, ...urlFilters });
  
      // Check if there's a search query, polygon, zpid, or address present to trigger the fetch
      if (searchQuery || polygon || zpid || address) {
        if (!fetchTriggeredRef.current) {
          fetchTriggeredRef.current = true;
  
          // First, prioritize fetching by zpid
          if (zpid) {
            fetchAdditionalDetails(zpid)
              .then((details) => {
                setSelectedListing(details);
                setListings([details]);
                completeProgress();
  
                // Normalize and update the URL with the correct address
                if (details && details.address) {
                  const { streetAddress, city, state, zipcode } = details.address;
                  const normalizedAddress = `${streetAddress}, ${city}, ${state} ${zipcode}`;
  
                  // Update the URL with the correct address if it's missing or invalid
                  if (!address || address === '[object Object]') {
                    if (details.address) {
                      queryParams.set('address', normalizedAddress); // Set the address directly without encoding
                      window.history.replaceState(null, '', `${location.pathname}?${queryParams.toString()}`);
                    }
                  }
                }
              })
              .catch((error) => {
                console.error("Failed to fetch details by zpid:", error);
  
                // Fallback to address fetch if zpid fetch fails
                if (address) {
                  fetchAdditionalDetailsByAddress(address)
                    .then((details) => {
                      setSelectedListing(details);
                      setListings([details]);
                      completeProgress();
                    })
                    .catch((addressError) => {
                      console.error("Failed to fetch details by address:", addressError);
                    });
                }
              });
          } else if (address) {
            // If no zpid but address is available, fetch by address
            fetchAdditionalDetailsByAddress(address)
              .then((details) => {
                setSelectedListing(details);
                setListings([details]);
                completeProgress();
              })
              .catch((error) => {
                console.error("Failed to fetch details by address:", error);
              });
          } else {
            // Fetch listings with search query or polygon if no address or zpid is available
            fetchListings(searchQuery, polygon, 'urlInitialization', '', urlFilters);
          }
        }
      }
  
      setUrlTriggeredUpdate(false);
    }
  }, [location.search, urlTriggeredUpdate, fetchListings, defaultFilters]);
  

  
  useEffect(() => {
    // Reset the fetch trigger after fetchListings is called
    if (!urlTriggeredUpdate) {
      fetchTriggered.current = false;
    }
  }, [urlTriggeredUpdate]);


  const handleClearFilters = useCallback(() => {
    setFilters(defaultFilters);
    setResetData(true);
  }, [defaultFilters]);

  useEffect(() => {
    if (resetData) {
      setListings([]);
      setFilteredData([]);
      setResetData(false);
    }
  }, [resetData]);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
    }
  }, []);

  useEffect(() => {
    const referrer = document.referrer;

    if (referrer.includes("google.com")) {
      const urlParams = new URLSearchParams(window.location.search);
      const initialFilters = {};

      urlParams.forEach((value, key) => {
        initialFilters[key] = value;
      });

      setFilters(initialFilters);
    }
  }, []);

  useEffect(() => {
    const handleRouteChange = (location) => {
      window.gtag('config', 'G-EHH43C18D1', {
        page_path: location.pathname,
      });
    };

    handleRouteChange(location);
    return () => window.removeEventListener('popstate', handleRouteChange);
  }, [location]);

  return (
    <ErrorBoundary>
      <ViewModeProvider>
        <TutorialProvider>
          <ErrorProvider>
            <Navbar closeAuthModal={closeAuthModal} showAuthModal={showAuthModal} setShowAuthModal={setShowAuthModal} />
            {showAuthModal && <AuthModal activeTab={activeTab} onClose={closeAuthModal} />}
            <SpeedInsights />
            <Suspense fallback={<LoadingSpinner />}>
              <Routes>
                <Route path="/" element={
                  <>
                    <Helmet>
                      <link rel="canonical" href="https://www.spearrealestategroup.com/home" />
                    </Helmet>
                    <Home



                      fetchListings={fetchListings}
                      listings={listings}
                      setSearchType={setSearchType}
                      filters={filters}
                      useQuery={useQuery}
                      setFilters={setFilters}
                      setAppliedFilters={setAppliedFilters}
                      handleClearFilters={handleClearFilters}
                      polygonCoords={polygonCoords}
                      setPolygonCoords={setPolygonCoords}
                      searchType={searchType}
                      setListings={setListings}
                      setFilteredData={setFilteredData}
                      fetchAdditionalDetails={fetchAdditionalDetails}
                      defaultFilters={defaultFilters}
                      updateUrlWithSearchParams={updateUrlWithSearchParams}
                      setUseQuery={setUseQuery}
                      appliedFilters={appliedFilters}
                      urlTriggeredUpdate={urlTriggeredUpdate}
                      setUrlTriggeredUpdate={setUrlTriggeredUpdate}
                      startProgress={startProgress}
                      completeProgress={completeProgress}
                      updateProgress={updateProgress}
                      loading={initialLoadingDone}
                    />
                  </>
                }
                />
                <Route path="/home" element={
                  <>
                    <Helmet>
                      <link rel="canonical" href="https://www.spearrealestategroup.com/home" />
                    </Helmet>
                    <Home



                      fetchListings={fetchListings}
                      listings={listings}
                      setSearchType={setSearchType}
                      filters={filters}
                      useQuery={useQuery}
                      setFilters={setFilters}
                      setAppliedFilters={setAppliedFilters}
                      handleClearFilters={handleClearFilters}
                      polygonCoords={polygonCoords}
                      setPolygonCoords={setPolygonCoords}
                      searchType={searchType}
                      setListings={setListings}
                      setFilteredData={setFilteredData}
                      fetchAdditionalDetails={fetchAdditionalDetails}
                      defaultFilters={defaultFilters}
                      updateUrlWithSearchParams={updateUrlWithSearchParams}
                      setUseQuery={setUseQuery}
                      appliedFilters={appliedFilters}
                      urlTriggeredUpdate={urlTriggeredUpdate}
                      setUrlTriggeredUpdate={setUrlTriggeredUpdate}
                      startProgress={startProgress}
                      completeProgress={completeProgress}
                      updateProgress={updateProgress}
                      loading={initialLoadingDone}
                    />
                  </>
                }
                />
                <Route path="/home" element={
                  <>
                    <Helmet>
                      <link rel="canonical" href="https://www.spearrealestategroup.com/home" />
                    </Helmet>
                    <Home



                      fetchListings={fetchListings}
                      listings={listings}
                      setSearchType={setSearchType}
                      filters={filters}
                      useQuery={useQuery}
                      setFilters={setFilters}
                      setAppliedFilters={setAppliedFilters}
                      handleClearFilters={handleClearFilters}
                      polygonCoords={polygonCoords}
                      setPolygonCoords={setPolygonCoords}
                      searchType={searchType}
                      setListings={setListings}
                      setFilteredData={setFilteredData}
                      fetchAdditionalDetails={fetchAdditionalDetails}
                      defaultFilters={defaultFilters}
                      updateUrlWithSearchParams={updateUrlWithSearchParams}
                      setUseQuery={setUseQuery}
                      appliedFilters={appliedFilters}
                      urlTriggeredUpdate={urlTriggeredUpdate}
                      setUrlTriggeredUpdate={setUrlTriggeredUpdate}
                      startProgress={startProgress}
                      completeProgress={completeProgress}
                      updateProgress={updateProgress}
                      loading={initialLoadingDone}
                    />
                  </>
                }
                />
                <Route path="/listings/:locationOrZpid?/:zpid?/"
                  element={
                    <>
                      <Helmet>
                        <link rel="canonical" href="https://www.spearrealestategroup.com/listings" />
                      </Helmet>
                      <Listings
                        queryParams={{ zpid, address, addressTriggered }}
                        selectedListing={selectedListing}
                        setSelectedListing={setSelectedListing}
                        cachedPropertyDetails={cachedPropertyDetails}
                        setCachedPropertyDetails={setCachedPropertyDetails}

                        /*                     updateMapCenter={updateMapCenter} */



                        urlTriggeredUpdate={urlTriggeredUpdate}
                        setUrlTriggeredUpdate={setUrlTriggeredUpdate}
                        updateUrlWithSearchParams={updateUrlWithSearchParams}
                        fetchListings={fetchListings}
                        listings={listings}
                        filters={filters}
                        setFilters={setFilters}
                        useQuery={useQuery}
                        setUseQuery={setUseQuery}
                        appliedFilters={appliedFilters}
                        setAppliedFilters={setAppliedFilters}
                        handleClearFilters={handleClearFilters}
                        polygonCoords={polygonCoords}
                        setPolygonCoords={setPolygonCoords}
                        searchType={searchType}
                        setSearchType={setSearchType}
                        totalResultCount={totalResultCount}
                        setListings={setListings}
                        setFilteredData={setFilteredData}
                        fetchAdditionalDetails={fetchAdditionalDetails}
                        defaultFilters={defaultFilters}
                        startProgress={startProgress}
                        completeProgress={completeProgress}
                        updateProgress={updateProgress}
                        loading={loading}
                      />
                    </>
                  }
                />
                <Route path="/auth/google/callback" element={<GoogleAuthCallback />} />
                <Route exact path="/reset" element={<Reset />} />
                <Route path="login" element={<AuthModal activeTab="login" />} />
                <Route path="register" element={<AuthModal activeTab="register" />} />
                <Route path="/featured-listings" element={<FeaturedListings />} />
                <Route path="/dashboard" element={<ProfileSettings to="/dashboard/ProfileSettings" />} />
                <Route path="/dashboard/:tabName" element={<Dashboard />} />
                <Route path="/contact" element={
                  <>
                    <Helmet>
                      <title>Contact Us - Spear Real Estate Group</title>
                      <meta name="description" content="Get in touch with Spear Real Estate Group." />
                      <link rel="canonical" href="https://www.spearrealestategroup.com/contact" />
                    </Helmet>
                    <Contact />
                  </>
                } />
                <Route path="/about" element={
                  <>
                    <Helmet>
                      <title>About Us - Spear Real Estate Group</title>
                      <meta name="description" content="Learn more about Spear Real Estate Group." />
                      <link rel="canonical" href="https://www.spearrealestategroup.com/about" />
                    </Helmet>
                    <AboutUs />
                  </>
                } />
                <Route path="/policy" element={
                  <>
                    <Helmet>
                      <title>Privacy Policy - Spear Real Estate Group</title>
                      <meta name="description" content="Read our privacy policy." />
                      <link rel="canonical" href="https://www.spearrealestategroup.com/policy" />
                    </Helmet>
                    <PrivacyPolicy />
                  </>
                } />
                <Route path="/terms" element={
                  <>
                    <Helmet>
                      <title>Terms of Service - Spear Real Estate Property Finder </title>
                      <meta name="description" content="Read our terms of service." />
                      <link rel="canonical" href="https://www.spearrealestategroup.com/terms" />
                    </Helmet>
                    <TermsOfService />
                  </>
                } />
                <Route path="/rate-search" element={
                  <>
                    <Helmet>
                      <title>Search Mortgage Rates - Spear Real Estate Group</title>
                      <meta name="description" content="View current mortgage rates and compare lenders." />
                      <link rel="canonical" href="https://www.spearrealestategroup.com/rate-search" />
                    </Helmet>
                    <SearchMortgageRates />
                  </>
                } />
                <Route path="/mortgage-calculator" element={
                  <>
                    <Helmet>
                      <title>Mortgage Calculator - Spear Real Estate Group</title>
                      <meta name="description" content="Calculate your mortgage payments with our mortgage calculator." />
                      <link rel="canonical" href="https://www.spearrealestategroup.com/mortgage-calculator" />
                    </Helmet>
                    <MortgageCalculator />
                  </>
                } />
                <Route path="/search-lenders" element={
                  <>
                    <Helmet>
                      <title>Search Lenders - Spear Real Estate Group</title>
                      <meta name="description" content="Find the best lenders with Spear Real Estate Group." />
                      <link rel="canonical" href="https://www.spearrealestategroup.com/search-lenders" />
                    </Helmet>
                    <SearchLenders />
                  </>
                } />
                <Route path="/refinance" element={
                  <>
                    <Helmet>
                      <title>Refinance Calculator - Spear Real Estate Group</title>
                      <meta name="description" content="Calculate your refinance options with our refinance calculator." />
                      <link rel="canonical" href="https://www.spearrealestategroup.com/refinance" />
                    </Helmet>
                    <RefinanceCalculator />
                  </>
                } />
                <Route path="/buyer-resources" element={
                  <>
                    <Helmet>
                      <title>Buyer Resources - Spear Real Estate Group</title>
                      <meta name="description" content="Find resources for home buyers." />
                      <link rel="canonical" href="https://www.spearrealestategroup.com/buyer-resources" />
                    </Helmet>
                    <BuyerResources />
                  </>
                } />
                <Route path="/agent-finder" element={
                  <>
                    <Helmet>
                      <title>Find a Top Buyer Agent</title>
                      <meta name="description" content="Find the best real estate agents with our agent finder." />
                      <link rel="canonical" href="https://www.spearrealestategroup.com/agent-finder" />
                    </Helmet>
                    <AgentFinderForm />
                  </>
                } />
                <Route path="/agent-landing" element={
                  <>
                    <Helmet>
                      <title>Find a Top Buyer Agent</title>
                      <meta name="description" content="Find the best real estate agents with our agent finder landing page." />
                      <link rel="canonical" href="https://www.spearrealestategroup.com/agent-landing" />
                    </Helmet>
                    <AgentFinderLanding />
                  </>
                } />
                <Route path="/renter-resources" element={
                  <>
                    <Helmet>
                      <title>Renter Resources - Spear Real Estate Group</title>
                      <meta name="description" content="Find resources for renters." />
                      <link rel="canonical" href="https://www.spearrealestategroup.com/renter-resources" />
                    </Helmet>
                    <RenterResources />
                  </>
                } />
                <Route path="/seller-resources" element={
                  <>
                    <Helmet>
                      <title>Seller Resources - Spear Real Estate Group</title>
                      <meta name="description" content="Find resources for home sellers." />
                      <link rel="canonical" href="https://www.spearrealestategroup.com/seller-resources" />
                    </Helmet>
                    <SellerResources />
                  </>
                } />
                <Route path="/seller-agent-finder" element={
                  <>
                    <Helmet>
                      <title>Find a Top Seller Agent</title>
                      <meta name="description" content="Find the best real estate agents with our agent finder." />
                      <link rel="canonical" href="https://www.spearrealestategroup.com/seller-agent-finder" />
                    </Helmet>
                    <SellerAgentFinderForm />
                  </>
                } />
                <Route path="/seller-agent-landing" element={
                  <>
                    <Helmet>
                      <title>Find a Top Seller Agent</title>
                      <meta name="description" content="Find the best real estate agents with our agent finder landing page." />
                      <link rel="canonical" href="https://www.spearrealestategroup.com/seller-agent-landing" />
                    </Helmet>
                    <SellerAgentFinderLanding />
                  </>
                } />
                <Route path="/list-with-us" element={
                  <>
                    <Helmet>
                      <title>List with Us - Spear Real Estate Group</title>
                      <meta name="description" content="List your home with Spear Real Estate Group." />
                      <link rel="canonical" href="https://www.spearrealestategroup.com/list-with-us" />
                    </Helmet>
                    <ListWithUs />
                  </>
                } />
                <Route path="/home-value" element={
                  <>
                    <Helmet>
                      <title>Home Value - Spear Real Estate Group</title>
                      <meta name="description" content="Find out the value of your home." />
                      <link rel="canonical" href="https://www.spearrealestategroup.com/home-value" />
                    </Helmet>
                    <HomeValue />
                  </>
                } />
                <Route path="/investor-resources" element={
                  <>
                    <Helmet>
                      <title>Investor Resources - Spear Real Estate Group</title>
                      <meta name="description" content="Find resources for investors." />
                      <link rel="canonical" href="https://www.spearrealestategroup.com/investor-resources" />
                    </Helmet>
                    <InvestorResources />
                  </>
                } />
                <Route path="/rental-market-trends" element={
                  <>
                    <Helmet>
                      <title>Rental Market Trends - Spear Real Estate Group</title>
                      <meta name="description" content="Stay updated with the latest rental market trends." />
                      <link rel="canonical" href="https://www.spearrealestategroup.com/rental-market-trends" />
                    </Helmet>
                    <RentalMarketTrends />
                  </>
                } />
                <Route path="/investment-calculators" element={
                  <>
                    <Helmet>
                      <title>Investment Calculators - Spear Real Estate Group</title>
                      <meta name="description" content="Calculate your investment options with our calculators." />
                      <link rel="canonical" href="https://www.spearrealestategroup.com/investment-calculators" />
                    </Helmet>
                    <InvestmentCalculator />
                  </>
                } />
                <Route path="/blog" element={<BlogList />} />
                <Route path="/blog/:postId" element={<BlogPostDetail />} />
                <Route path="/sitemap" element={
                  <>
                    <Helmet>
                      <title>Sitemap - Spear Real Estate Group</title>
                      <meta name="description" content="Explore the sitemap of Spear Real Estate Group to find all available pages and resources." />
                      <link rel="canonical" href="https://www.spearrealestategroup.com/sitemap" />
                    </Helmet>
                    <SitemapPage />
                  </>
                } />

                <Route path="/schedule" element={

                  <>
                    <Helmet>
                      <title>Book a Meeting - Spear Real Estate Group</title>
                      <meta name="description" content="Schedule a meeting with us using our online booking system." />
                      <link rel="canonical" href="https://www.spearrealestategroup.com/schedule" />
                    </Helmet>
                    <BookingPage />
                  </>
                } />
              </Routes>
              <Suspense>
                <SiteFooter />
              </Suspense>
            </Suspense>
          </ErrorProvider>
        </TutorialProvider>
      </ViewModeProvider>
    </ErrorBoundary>
  );
}

export default App;
